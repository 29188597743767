import { ExtractRouteParams } from "./types";
// eslint-disable-next-line no-restricted-imports
import { useParams as useRRParams } from "react-router-dom";

/**
 * Uses generic type of route to extract parameter's names
 */
export function useParams<T extends string>(): ExtractRouteParams<T, string> {
  return useRRParams() as ExtractRouteParams<T, string>;
}
