import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string;
}

const SectionTitle: FunctionComponent<Props> = ({
  title,
  className,
  ...sectionTitleProps
}) => {
  return (
    <h2 {...sectionTitleProps} className={cx(className, "section-title")}>
      {title}
    </h2>
  );
};

export default SectionTitle;
