// eslint-disable-next-line no-restricted-imports
import { RoutesProps, Routes as RRDRoutes, Route } from "react-router-dom";
import React from "react";
import NotFound from "./NotFound";

interface Props extends RoutesProps {
  noNotFound?: boolean;
}

const Routes = ({
  noNotFound = false,
  children,
  ...routesProps
}: Props): React.ReactElement | null => {
  return (
    <RRDRoutes {...routesProps}>
      <>
        {children}
        <Route element={React.Fragment} index />
        <Route element={<NotFound />} path={"*"} />
      </>
    </RRDRoutes>
  );
};

export default Routes;
