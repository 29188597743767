import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const CardBody: FunctionComponent<Props> = ({
  className,
  ...cardBodyProps
}) => {
  return <div {...cardBodyProps} className={cx("card-body", className)} />;
};

export default CardBody;
