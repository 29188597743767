import React, { ReactElement, useMemo } from "react";
import { ExtractRouteParams } from "../types";
import {
  NavLink as RRDNavLink,
  NavLinkProps as RRDNavLinkProps,
} from "react-router-dom";
import generatePath from "../generatePath";
import { cx } from "@emotion/css";

interface BaseProps
  extends Omit<React.PropsWithoutRef<RRDNavLinkProps>, "className"> {
  className?: string;
}

interface OwnProps<T extends string> extends BaseProps {
  to: T;
  params?: never;
  activeClassName?: string;
  queryParams?: Record<string, unknown>;
}
interface OwnPropsWithParams<T extends string> extends BaseProps {
  to: T;
  params: ExtractRouteParams<T>;
  activeClassName?: string;
  queryParams?: Record<string, unknown>;
}

export type Props<T extends string> = (T extends
  | `${string}:${string}/${string}`
  | `${string}:${string}`
  ? OwnPropsWithParams<T>
  : OwnProps<T>) &
  React.RefAttributes<HTMLAnchorElement>;

const NavLink = <T extends string>({
  activeClassName = "active",
  to,
  params,
  queryParams,
  className,
  ...otherProps
}: Props<T>): ReactElement => {
  const path = useMemo(() => generatePath(to, params, queryParams), [
    to,
    params,
    queryParams,
  ]);

  return (
    <RRDNavLink
      {...otherProps}
      className={({ isActive }) =>
        isActive ? cx([className, activeClassName]) : className || ""
      }
      to={path}
    />
  );
};

export default NavLink;
